<template>
    <div class="relative mt-8">
        <div class="hours">
            <span v-for="hour in hours" :key="hour" :style="{ 'left': calculateWidth(hour * 60) }" class="pl-1">{{ hour }}</span>
        </div>
        <span
            class="absolute bg-green-300 h-full w-8 opacity-25"
            :style="{
                'left': leftPosition(chosenDate),
                'width': calculateWidth(duration)
            }"
        />
        <div class="employees pt-10">
            <div v-for="item in employeesBusy" :key="item.employee" class="flex relative items-end pl-2 border-b h-10">
                <span>{{ item.employee }}</span>
                <span v-if="item.problematic">👎</span>
                <span v-else>👌</span>

                <el-tooltip
                    v-for="event in item.events"
                    :key="event.id"
                    :enterable="false"
                    :openDelay="500"
                    :content="event.client_name + ' ' + event.client_surname"
                    class="item"
                    effect="dark"
                    placement="left"
                >
                    <div
                        class="bg-blue-300 h-4 absolute opacity-50 rounded-full mb-2"
                        :style="{
                            'left': leftPosition(event.started_event_at),
                            'width': calculateWidth(event.duration),
                            'background-color': item.problematic ? '#ff9d9d' : ''
                        }"
                    />
                </el-tooltip>

                <el-tooltip
                    v-for="itemT in item.travel"
                    :key="itemT.start_time"
                    :enterable="false"
                    :openDelay="500"
                    :content="'(' + itemT.distance_human + ') ' + itemT.time_travel_human + ' travel time'"
                    class="item"
                    effect="dark"
                    placement="left"
                >
                    <div
                        class="bg-orange-300 h-4 absolute opacity-50 rounded-full mb-2"
                        :style="{
                            'left': leftPosition(itemT.start_time),
                            'width': calculateTravelWidth(itemT.time_travel_value),
                        }"
                    />
                </el-tooltip>
            </div>
            <div v-for="item in employeesFree" :key="item.employee" class="flex relative items-end pl-2 border-b h-10">
                <span>{{ item.employee }}</span>
                <span v-if="item.problematic">👎</span>
                <span v-else>👌</span>

                <el-tooltip
                    v-for="event in item.events"
                    :key="event.id"
                    :enterable="false"
                    :openDelay="500"
                    :content="event.client_name + ' ' + event.client_surname"
                    class="item"
                    effect="dark"
                    placement="left"
                >
                    <div
                        class="bg-blue-300 h-4 absolute opacity-50 rounded-full mb-2"
                        :style="{
                            'left': leftPosition(event.started_event_at),
                            'width': calculateWidth(event.duration),
                            'background-color': item.problematic ? '#ff9d9d' : ''
                        }"
                    />
                </el-tooltip>

                <el-tooltip
                    v-for="itemT in item.travel"
                    :key="itemT.start_time"
                    :enterable="false"
                    :openDelay="500"
                    :content="'(' + itemT.distance_human + ') ' + itemT.time_travel_human + ' travel time'"
                    class="item"
                    effect="dark"
                    placement="left"
                >
                    <div
                        class="bg-orange-300 h-4 absolute opacity-50 rounded-full mb-2"
                        :style="{
                            'left': leftPosition(itemT.start_time),
                            'width': calculateWidth(itemT.time_travel_value),
                        }"
                    />
                </el-tooltip>
            </div>
        </div>
    </div>
</template>
<script>
import range from 'lodash/range';

import customParseFormat from 'dayjs/plugin/customParseFormat';

export default {
    props: ['chosenDate', 'duration', 'addressUuid', 'coords'],

    data() {
        return {
            hours:         range(24),
            employeesBusy: [],
            employeesFree: [],
        };
    },

    watch: {
        chosenDate:  'getEmployees',
        duration:    'getEmployees',
        addressUuid: 'getEmployees',
    },

    created() {
        // this.getEmployees();
    },

    methods: {
        async getEmployees() {
            if (this.$wait.is('*')) return;
            this.$wait.start('loading');

            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/smart_calendar/events_by_employee_by_day`, {
                    params: {
                        chosen_date: this.$dayjs(this.chosenDate).unix(),
                        duration:    this.duration,
                        map_lat:     this.coords.map_lat,
                        map_lng:     this.coords.map_lng,
                    },
                });
                this.employeesBusy = data.with_events;
                this.employeesFree = data.without_events;
            } catch {}

            this.$wait.end('loading');
        },

        leftPosition(value) {
            const diff = this.$dayjs(value).diff(this.$dayjs(value).startOf('day'), 'minute');
            return `${diff / 14.4}%`;
        },

        calculateWidth(value) {
            return `${value / 14.4}%`;
        },

        calculateTravelWidth(value) {
            return this.calculateWidth(value < 10 ? 15 : value);
        },
    },
};
</script>
<style scoped>
    .hours  {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .hours span {
        position: absolute;
        height: 100%;
        border-left: 1px solid #eee;
    }
</style>

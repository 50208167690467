var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative mt-8"},[_c('div',{staticClass:"hours"},_vm._l((_vm.hours),function(hour){return _c('span',{key:hour,staticClass:"pl-1",style:({ 'left': _vm.calculateWidth(hour * 60) })},[_vm._v(_vm._s(hour))])}),0),_c('span',{staticClass:"absolute bg-green-300 h-full w-8 opacity-25",style:({
            'left': _vm.leftPosition(_vm.chosenDate),
            'width': _vm.calculateWidth(_vm.duration)
        })}),_c('div',{staticClass:"employees pt-10"},[_vm._l((_vm.employeesBusy),function(item){return _c('div',{key:item.employee,staticClass:"flex relative items-end pl-2 border-b h-10"},[_c('span',[_vm._v(_vm._s(item.employee))]),(item.problematic)?_c('span',[_vm._v("👎")]):_c('span',[_vm._v("👌")]),_vm._l((item.events),function(event){return _c('el-tooltip',{key:event.id,staticClass:"item",attrs:{"enterable":false,"openDelay":500,"content":event.client_name + ' ' + event.client_surname,"effect":"dark","placement":"left"}},[_c('div',{staticClass:"bg-blue-300 h-4 absolute opacity-50 rounded-full mb-2",style:({
                        'left': _vm.leftPosition(event.started_event_at),
                        'width': _vm.calculateWidth(event.duration),
                        'background-color': item.problematic ? '#ff9d9d' : ''
                    })})])}),_vm._l((item.travel),function(itemT){return _c('el-tooltip',{key:itemT.start_time,staticClass:"item",attrs:{"enterable":false,"openDelay":500,"content":'(' + itemT.distance_human + ') ' + itemT.time_travel_human + ' travel time',"effect":"dark","placement":"left"}},[_c('div',{staticClass:"bg-orange-300 h-4 absolute opacity-50 rounded-full mb-2",style:({
                        'left': _vm.leftPosition(itemT.start_time),
                        'width': _vm.calculateTravelWidth(itemT.time_travel_value),
                    })})])})],2)}),_vm._l((_vm.employeesFree),function(item){return _c('div',{key:item.employee,staticClass:"flex relative items-end pl-2 border-b h-10"},[_c('span',[_vm._v(_vm._s(item.employee))]),(item.problematic)?_c('span',[_vm._v("👎")]):_c('span',[_vm._v("👌")]),_vm._l((item.events),function(event){return _c('el-tooltip',{key:event.id,staticClass:"item",attrs:{"enterable":false,"openDelay":500,"content":event.client_name + ' ' + event.client_surname,"effect":"dark","placement":"left"}},[_c('div',{staticClass:"bg-blue-300 h-4 absolute opacity-50 rounded-full mb-2",style:({
                        'left': _vm.leftPosition(event.started_event_at),
                        'width': _vm.calculateWidth(event.duration),
                        'background-color': item.problematic ? '#ff9d9d' : ''
                    })})])}),_vm._l((item.travel),function(itemT){return _c('el-tooltip',{key:itemT.start_time,staticClass:"item",attrs:{"enterable":false,"openDelay":500,"content":'(' + itemT.distance_human + ') ' + itemT.time_travel_human + ' travel time',"effect":"dark","placement":"left"}},[_c('div',{staticClass:"bg-orange-300 h-4 absolute opacity-50 rounded-full mb-2",style:({
                        'left': _vm.leftPosition(itemT.start_time),
                        'width': _vm.calculateWidth(itemT.time_travel_value),
                    })})])})],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }